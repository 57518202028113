export default {
  "API_KEY": "NgJBnCEokG4KAfo6tdhL33xKYTdTdrX76GwqHElP",
  "API_URL": "https://running-api-admin-stage.asqq.io",
  "COGNITO_APP_CLIENT_ID": "2tt58v1eqqknkke5fha4t4ems6",
  "COGNITO_DOMAIN": "running-api-admin-stage.auth.eu-central-1.amazoncognito.com",
  "COGNITO_REDIRECT_SIGN_IN": "https://running-admin-stage.asqq.io/",
  "COGNITO_REDIRECT_SIGN_OUT": "https://running-admin-stage.asqq.io/",
  "COGNITO_USER_POOL_ID": "eu-central-1_BMdUEcgpt",
  "REGION": "eu-central-1"
}
